<template>
    <form @submit.prevent="onSubmit">
        <div class="forgot-form">
        <!-- Email -->
        <VField>
            <VControl icon="feather:user">
            <input
                id="email"
                v-model="email"
                class="input"
                type="text"
                placeholder="E-mail"
                autocomplete="E-mail"
            />
            </VControl>
        </VField>

        <!-- Submit -->
        <VControl class="forgot">
            <VButton
                :loading="isLoading"
                color="primary"
                type="submit"
                bold
                fullwidth
                raised
            >
            Recover password
            </VButton>
        </VControl>

        <div class="forgot-link has-text-centered">
            <router-link :to="{ name: 'login' }" class="has-text-white">Back to login</router-link>
        </div>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import VButton from '../../components/base/button/VButton.vue';
import VField from '../../components/base/form/VField.vue';
import VControl from '../../components/base/form/VControl.vue';
import store, {ActionTypes} from "../../store";
import {useRouter} from 'vue-router'
const router = useRouter()
export default {
  name: 'ForgotForm',

  components: {
    VButton,
    VField,
    VControl
  },
  setup() {
      const email = ref('');
      const router=useRouter();
      const onSubmit = () => {
        store.dispatch(ActionTypes.PASSWORD_TOKEN_REQUEST, {'email':email.value}).then(() => {
          router.push({ name: 'Index' })
        })
      }

      return {
        email,
        onSubmit
      }
  }
};
</script>


<style lang="scss">
@import '../../scss/abstracts/_variables.scss';
@import '../../scss/abstracts/_mixins.scss';
@import '../../scss/pages/auth/_auth.scss';

form .svgContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 1em;
  border-radius: 50%;
  background: none;
  border: solid 2.5px #3A5E77;
  overflow: hidden;
  pointer-events: none;
  display: none;
}
form .svgContainer div {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
}
form .svgContainer .mySVG {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


</style>
